#coming {
    background: #1F1C25;
    min-width: 100%;
    min-height: 100vh;
}

#coming .top {
    text-align: left;
    padding: 5vh;
}

#coming .logo {
    max-width: 150px;
}

#coming .title {
    text-align: center;
    padding-top: 20vh;
}

#coming .textGrid {
    text-align: right;
}

#coming .buttonGrid {
    text-align: left;
}

#coming .button {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

#coming .button .text {
    padding-left: 3rem;
    padding-right: 3rem;
}


@media only screen and (max-width: 600px) {
    #coming .title {
        text-align: center;
        padding-top: 5vh;
    }
    
    #coming .textGrid {
        text-align: center;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    
    #coming .buttonGrid {
        text-align: center;
    }

    #coming .button {
        margin-top: 0rem;
        margin-bottom: 0rem;
    }
    
    #coming .button .text {
        padding-left: 0rem;
        padding-right: 0rem;
    }
}

@media only screen and (min-width: 601px)  and (max-width: 900px) {
    #coming .title {
        text-align: center;
        padding-top: 5vh;
    }

    #coming .textGrid {
        text-align: center;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    
    #coming .buttonGrid {
        text-align: center;
    }

    #coming .button {
        margin-top: 0rem;
        margin-bottom: 0rem;
    }
    
    #coming .button .text {
        padding-left: 0rem;
        padding-right: 0rem;
    }
}