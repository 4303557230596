#download {
    width: 100%;
    margin: 0px;
}

#download .downloadGrid {
    position: relative !important;
    width: 100% !important;
    margin-right: auto !important;
    margin-left: auto !important;
    padding: 0px !important;
    text-align: end;
}

#download .downloadText {
    display: inline-block;
    text-align: end;
    max-width: 640px;
}

#download .downloadTextItem {
    display: inline-block;
    text-align: end;
    max-width: 640px;
}


#download .hand {
    max-width: 80%;
}

#download .downloadSpacer {
    padding-top: 2vh;
    padding-left: 2rem;
}

@media only screen and (max-width: 600px) {

    #download .downloadText {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }

    #download .downloadSpacer {
        padding-top: 1vh;
        padding-left: 0rem;
    }

    #download .downloadText {
        max-width: 100%;
    }
    
    #download .downloadTextItem {
        max-width: 100%;
    }
}
  
@media only screen and (min-width: 601px)  and (max-width: 900px) {

    #download .downloadText {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }

    #download .downloadSpacer {
        padding-top: 0vh;
    }
    
    #download .downloadText {
        max-width: 100%;
    }
    
    #download .downloadTextItem {
        max-width: 100%;
    }
}