#title.sectionTitle {
    font-style: normal;
    font-weight: 400;
    font-size: 64px;
    line-height: 77px;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 2rem 0px;
}

@media only screen and (min-width: 601px)  and (max-width: 900px) {
    #title.sectionTitle {
        font-size: 32px;
        line-height: 130%;
    }
}

@media only screen and (max-width: 600px) {
    #title.sectionTitle {
        font-size: 32px;
        line-height: 130%;
    }
}