#header {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: left;
    padding-top: 3vh;
}

#header .logo {
   max-width: 150px;
   align-self: flex-start;
}