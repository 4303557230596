#navbar .main {
    width: 1200px;
}

#navbar .button {
    width: 150px;
}

#navbar .logo {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-left: unset !important;
}

#navbar .logoImage {
    max-width: 150px;
    align-self: flex-start;
}

#navbar .MuiGrid-item {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

#navbar .menu {
    display: flex;
    justify-content: center;
    align-items: center;
}

#navbar .menu div[data-nav] {
    display: flex;
    
}

#navbar .social {
    display: flex;
    justify-content: end;
    align-items: center;
}

#navbar .social>a {
    line-height: 0;
}


#navbar .social .social-logo {
    margin: 0px 0.25rem;
}

#navbar .navbarItem {
    font-weight: 400;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 1rem;
    width: 90px;
    display: flex;
    justify-content: center;
    text-decoration: unset;
}

#navbar .navbarItem:hover {
    cursor: pointer;
}

#navbar .navbarItem.active::after {
    content:"";
    background: #fff;
    display: block;
    height: 2px;
    width: 75px;
    position: absolute;
    bottom: 0;
}

#navbar .mobile {
    display: none;
}

@media only screen and (max-width: 1200px) { 

    #navbar .mobile {
        display: flex;
    }

    #navbar .desktop {
        display: none;
    }

    #navbar .mobile .drawer {
        margin-left: auto;
    }

    #navbar img {
        display: flex;
        justify-content: center;
    }

    #mobileDrawer .socials.links.drawer {
        display: flex;
        justify-content: space-evenly;
    }
    
}