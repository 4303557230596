#footer {
    width: 100%;
    border-top: 1px solid #D1D3D4;
}

#footer .footerContent {
    position: relative !important;
    width: 100% !important;
    max-width: 1200px !important;
    margin-right: auto !important;
    margin-left: auto !important;
    padding: 1rem 60px !important;
}

#footer .links {
    display: flex;
    flex-direction: column;
}

#footer .links a {
    font-family: 'Lato';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: #F8F9F9;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 1em 0px;
    text-decoration: none;
}

#footer .links a:hover {
    cursor: pointer;
}

#footer .apps .icons {
    display: flex;
    flex-direction: column;
}

#footer .apps a:hover {
    cursor: pointer;
}

#footer .logo {
    width: 70%;
}

#footer .header {
    font-family: 'Lato';
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 140%;
    color: #F8F9F9;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-bottom: 1em;
    margin-top: 0;
}

#footer .copyright {
    font-family: 'Lato';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 150%;
    color: #F8F9F9;
}

#footer .links.mobile {
    display: none;
}

@media only screen and (max-width: 600px) {
    #footer .footerContent {
        padding: 1rem !important;
    }

    #footer .links.mobile .divider {
        border: 1px solid #D1D3D4;
        margin: 1rem -1rem !important;
    }

    #footer .apps {
        display: flex;
        flex-direction: column;
    }

    #footer .links.desktop.tablet {
        display: none;
    }

    #footer .links.mobile {
        display: unset;
    }
    
    #footer .links.mobile .collapsedLinks a {
        display: flex;
        flex-direction: column;
        margin: 0.5em 0.5em;
    }
    
    #footer .links.mobile .button {
        padding-left: 0; 
        padding-right: 0;
    }
    
    #footer .links.mobile .text span {
        font-family: 'Lato';
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 150%;
        color: #D1D3D4;
    }
    
    #footer .apps .header {
        font-family: 'Lato';
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 150%;
        color: #D1D3D4;
    }

    #footer .apps .icons {
        display: unset;
        flex-direction: unset;
    }
    
  }
  
  @media only screen and (min-width: 601px)  and (max-width: 900px) {
    #footer .footerContent {
        padding: 0 1rem !important;
    }

    #footer .links a {
        margin: 0.5em 0px;
    }
  }