#Plans {
    position: relative !important;
    width: 100% !important;
    max-width: 1200px !important;
    margin-right: auto !important;
    margin-left: auto !important;
    padding-bottom: 60px !important;
    padding-top: 20px !important;
    margin-top: 85px;
}

#Plans .title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: bold;
    font-size: 64px;
    line-height: 120%;
    color: #ffffff;
    text-align: center;
}

#Plans .mobile {
    font-family: 'Inter';
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 120%;
    padding-top: 3vh;
    color: #ffffff;
    text-align: center;
}

#Plans .smallTitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 130%;
    text-align: center;
    color: #ffffff;
    padding-top: 3vh;
}

#Plans .price {
    font-family: 'Lato';
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 140%;
    text-align: center;
    color: #ffffff;
    padding-top: 2vh;
    padding-bottom: 3vh;
}

#Plans .rowTitle {
    font-family: 'Lato';
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 140%;
    color: #ffffff;
}

#Plans .rowText {
    font-family: 'Lato';
    font-style: normal;
    font-size: 14px;
    line-height: 150%;
    color: #ffffff;
}

#Plans .rowDataText {
    font-family: 'Lato';
    font-style: normal;
    font-size: 14px;
    line-height: 150%;
    padding-top: 1.3vh;
    color: #ffffff;
}

#Plans .rowDataImage {
    padding-top: 1vh;
}

#Plans .headerRowTop {
    border-bottom: 2px solid #01E0B7;
    min-height: 30vh;
}

#Plans .textRowTop {
    border-top: 2px solid #01E0B7;
    margin-top: 2vh;
}

#Plans .headerRowBottom {
    min-height: 20vh;
}

#Plans .headerRowSelectedTop {
    background-color: #1F1C25;
    border: 2px solid #01E0B7;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    min-height: 30vh;
}

#Plans .headerRowSelectedBottom {
    background-color: #1F1C25;
    border-left: 2px solid #01E0B7;
    border-right: 2px solid #01E0B7;
    border-bottom: 2px solid #01E0B7;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    min-height: 30vh;
}

#Plans .dataRow {
    min-height: 10vh;
    text-align: center;
    vertical-align: middle;
    display: "flex";
    justify-content: center;
    align-content: center;
}

#Plans .dataRowStart {
    min-height: 10vh;
    text-align: left;
}

#Plans .dataRowSelected {
    background-color: #1F1C25;
    border-left: 2px solid #01E0B7;
    border-right: 2px solid #01E0B7;
}

#Plans .dataItem {
    min-height: 8vh;
}

#Plans .button {
    min-width: 60%;
}


@media only screen and (max-width: 600px) { 

    #Plans .price {
        font-size: 14px;
        padding-bottom: 1vh;
    }

    #Plans .title {
        padding-bottom: 1vh;
    }

    #Plans .smallTitle {
        font-size: 24px;
    }

    #Plans .headerRowTop {
        border-bottom: 0px;
        min-height: 25vh;
        position: sticky;
    }

    #Plans .headerRowSelectedTop {
        background-color: #1F1C25;
        border: 2px solid #01E0B7;
        border-radius: 10px;
        min-height: 25vh;
        position: sticky;
    }
    
    #Plans .dataRowSelected {
        background-color: #1F1C25;
        border: 2px solid #01E0B7;
        border-radius: 10px;
    }
        
    #Plans .dataRowStart {
        min-height: 6vh;
        text-align: center;
        padding-top: 1vh;
    }

    #Plans .dataRow {
        min-height: 6vh;
    }

    #Plans .button {
        max-width: 85%;
    }

}