#faq {
    background: #F8F9F9;
    min-height: 100vh;
    margin-top: 85px;
}

#faq #search {
    background: #1F1C25;
    width: 100%;
    margin: 0px;
}

#faq #search .grid {
    position: relative !important;
    width: 100% !important;
    max-width: 1200px !important;
    margin-right: auto !important;
    margin-left: auto !important;
    padding: 4rem 60px !important;
}

#faq #search .title {
    font-family: 'Lato';
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 140%;
    color: #1B1232;
    flex: none;
    order: 0;
    flex-grow: 0;
}

#faq #cards {
    padding-bottom: 4rem !important;
    padding-top: 2rem !important;
}

#faq #cards:hover {
    cursor: pointer;
}

#faq #cards #faqItem {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(139, 139, 139, 0.24);
    border-radius: 8px;
}

#faq #cards #faqItem .faqName {
    font-family: 'Lato';
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 140%;
    color: #1B1232;
    flex: none;
    order: 0;
    flex-grow: 0;
}

#faq #cards .title {
    font-family: 'Lato';
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 130%;
    color: #1B1232;
    flex: none;
    order: 0;
    flex-grow: 0;
}

#faq #submitRequest .grid .title {
    font-family: 'Lato';
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 130%;
    color: #1B1232;
    flex: none;
    order: 0;
    flex-grow: 0;
}

#faq #submitRequest .grid .subtitle {
    font-family: 'Lato';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 140%;
    text-align: center;
    color: #413E57;
    flex: none;
    order: 1;
    flex-grow: 0;
}

#faq #submitRequest .grid .button {
    background: linear-gradient(90deg, #CA40F0 0%, #3E80DC 100%);
    border-radius: 8px;
    flex: none;
    order: 1;
    flex-grow: 0;
}

#faq #submitRequest .grid .button span {
    font-family: 'Lato';
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 140%;
    text-align: center;
    color: #F8F9F9;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0.5rem 3rem;
}

@media only screen and (max-width: 600px) {
    #faq #search .grid {
        padding: 2rem 0.5rem !important;
    }

    #faq .main {
        padding: 0px 1rem !important;
    }

    #faq #cards .card.MuiGrid-item {
        padding-left: 0px !important;
    }

    #faq #cards {
        padding-bottom: 2rem !important;
        padding-top: 2rem !important;
    }
    
}

@media only screen and (min-width: 601px)  and (max-width: 900px) {
    #faq .main {
        padding: 0px 1rem !important;
    }

    #faq #cards {
        padding-bottom: 2rem !important;
        padding-top: 2rem !important;
    }

    #faq #cards .card.MuiGrid-item:nth-child(even) {
        padding-left: 0px !important;
    }
    
}