#ourPlans {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

#ourPlans .plans{
    padding: 2rem 0;
}

#ourPlans .planCard{
    background: #2a2e32;
    border-radius: 8px;
    padding: 2rem 1rem;
}


#ourPlans .planCard .header{
    text-align: center;
}

#ourPlans .planCard .content{
    font-family: 'Lato';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    color: #F8F9F9;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 16px;
    min-height: 10rem;
}

#ourPlans .planCard .content .planListIcon{
    min-width: 2rem;
}

#ourPlans .planCard .header span.MuiCardHeader-subheader{
    font-family: 'Lato';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 140%;
    text-align: center;
    color: #F8F9F9;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 16px 0px;
}

#ourPlans .planCard .header span.MuiCardHeader-title{
    font-family: 'Inter';
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 130%;
    text-align: center;
    color: #F8F9F9;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 16px 0px;
}

#ourPlans .planCard .actions{
    justify-content: center;
}

#ourPlans .planCard .divider{
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    color: #F8F9F9;
}

#ourPlans .planCard .button .text{
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

@media only screen and (max-width: 600px) {
    #ourPlans {
        padding: 0px 0.5rem !important;
    }
}

@media only screen and (min-width: 601px) and (max-width: 900px) {
    #ourPlans {
        padding: 0px 0.5rem !important;
    }

    #ourPlans .planCard .content{
        min-height: 15rem;
    }

    #ourPlans .planCard .header span.MuiCardHeader-title{
        font-size: 28px;
    }
}