#primaryBtn {
    background: #01E0B7;
    border-radius: 8px;
    height: 56px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 140%;
    text-align: center;
    color: #1F1C25;
    text-transform: none;
    padding-left: 1rem;
    padding-right: 1rem;
}

#secondaryBtn {
    border: 1px solid #1B1232;
    box-sizing: border-box;
    border-radius: 8px;
    background: #ffffff;
    flex: none;
    order: 0;
    height: 56px;
    flex-grow: 0;
    margin: 0px 16px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 140%;
    text-align: center;
    color: #1B1232;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 10px;
    text-transform: none;
}

#outlinedBtn {
    box-sizing: border-box;
    background: linear-gradient(97.96deg, #01DDB9 0%, #0190DA 50%, #4249B5 100%);
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    flex: none;
    order: 0;
    height: 49px;
    flex-grow: 0;
    margin: 0px 16px;
    font-style: normal;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 10px;
    text-transform: none;
}

#textBtn {
    background: #1F1C25;
    border-radius: 6px;
    flex: none;
    order: 0;
    height: 49px;
    flex-grow: 0;
    margin: 0px 16px;
    font-style: normal;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: #ffffff;
    border: 1px solid #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 10px;
    text-transform: none;
    box-shadow: none;
}