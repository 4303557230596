#getStarted {
    display: flex;
    align-items: center;
}

#getStarted .getBox{
    display: inline-block;
    align-items: center;
    max-width: 600px;
    padding-bottom: 5rem;
}

#getStarted .startedApps{
    padding-top: 3rem;
}

#getStarted .startedAGridRight{
    text-align: right;
}

#getStarted .startedAGridLeft{
    text-align: left;
}

#getStarted .downloadImage{
    width: 90%;
}

@media only screen and (max-width: 600px) {
    #getStarted {
        text-align: center;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    
}

@media only screen and (min-width: 601px)  and (max-width: 900px) {
    #getStarted {
        text-align: center;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    
}