#terms {
    width: 100%;
    text-align: center;
    color: #ffffff;
    margin: 1rem;
    margin-top: 3rem;
}

#terms .termsBox {
    max-width: 1200px;
    margin-top: 3rem;
    margin-bottom: 3rem;
    display: inline-block;
    text-align: left;
}

#terms .title {
    font-size: 35px;
    text-align: left;
}

#terms .subtitle {
    font-size: 25px;
    text-align: left;
}
