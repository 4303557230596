#saveTheOcean {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    padding-top: 12vh;
}

#saveTheOcean .button {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

#saveTheOcean .button .text {
    padding-left: 3rem;
    padding-right: 3rem;
}

.saveOceanImage {
   width: 100%;
   padding-top: 3rem;
}

@media only screen and (max-width: 600px) {
    #saveTheOcean {
        padding: 0px 0.5rem !important;
    }
}

@media only screen and (min-width: 601px)  and (max-width: 900px) {
    #saveTheOcean {
        padding: 0px 0.5rem !important;
    }
}