#subTitleGreen.sectionSubTitleGreen {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 140%;
    color: #01E0B7;
    flex: none;
    order: 1;
    flex-grow: 0;
}

@media only screen and (min-width: 601px)  and (max-width: 900px) {
    #subTitleGreen.sectionSubTitleGreen {
        font-size: 14px;
        line-height: 140%;
    }
}

@media only screen and (max-width: 600px) {
    #subTitleGreen.sectionSubTitleGreen {
        font-size: 14px;
        line-height: 140%;
    }
}