.navigation .menuTitle {
    font-family: 'Lato';
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 140%;
    color: #707685;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.navigation nav {
    padding-top: 0 !important;
}

.navigation .menuTitle.selected {
    color: #1B1232;
}

.navigation .menuSubTitle {
    font-family: 'Lato';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 140%;
    color: #1B1232;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-left: 0.5rem;
}

.navigation .menuSubTitle.selected {
    color: #105099;
}

.navigation .collapse {
    border-left: 4px solid #105099;
}

.navigation .button {
    padding-left: 0px;
    padding-top: 0px;
}


.content .title {
    font-family: 'Lato';
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 130%;
    color: #1B1232;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.content .subtitle {
    font-family: 'Lato';
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 140%;
    color: #1B1232;
    flex: none;
    order: 0;
    flex-grow: 0;
    padding-bottom: 2rem;
}