#crypto {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    background: #312E38;
    padding: 2rem;
}

#crypto .cell {
    border-right: 1px solid #707685;
    padding-left: 2rem;
}

#crypto .cell.last {
    border-right: none;
}

#crypto .logo {
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: left;
    max-height: 20px;
}

#crypto .name {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    color: #fff;
    text-align: left;
}

#crypto .pairText {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 40px;
    color: #B5B9C4;
    text-align: left;
    vertical-align: middle;
}

#crypto .pairChange {
    width: 63px;
    height: 24px;
    line-height: 24px;
    background: #06C374;
    border-radius: 20px;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    text-align: center;
    color: #FFFFFF;
    flex: none;
}

#crypto .pairChange.red {
    background: #FE1D45;
}

#crypto .price {
    padding-top: 1rem;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
    color: #FFFFFF;
    text-align: left;
}

#crypto .priceChange {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #B5B9C4;
    text-align: left;
}


.cryptoImage {
   width: 100%;
   padding-top: 3rem;
}

@media only screen and (max-width: 600px) {
    #crypto {
        padding: 0px 0.5rem !important;
    }
}

@media only screen and (min-width: 601px)  and (max-width: 900px) {
    #crypto {
        padding: 0px 0.5rem !important;
    }
}